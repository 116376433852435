//React imports
import React, { useState,useEffect } from 'react';
import { Helmet } from "react-helmet";
//CSS imports
import './App.css'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
export default function App() {
  //State`s
  const [imageData, setimageData] = useState("")
  const [imageDataText, setimageDataText] = useState("")
  const [imageDataLoading, setimageDataLoading] = useState(false)
  const [DataLoading, setDataLoading] = useState(false)
  const [kundeID, setkundeID] = useState("")
  const [fakturaID, setfakturaID] = useState("")

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName ="Godt Brød faktura "+ fakturaID+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
  //Hent faktura fra API
  async function getFaktura() {
    
    setimageData("")
    setimageDataLoading(false)
    setDataLoading(true)

    const url2 = "https://gbrest.azurewebsites.net/invoice?KundeID=" + kundeID + "&FakturaID=" + fakturaID + ""
    const result = await fetch(url2)

    const resultXML = await result.text().then(result => result)

    if (resultXML.slice(47, 54) == "<error>") {
      setimageDataLoading(false)
      setimageData("")
      setimageDataText(["Du har ikke skrevet inn gyldig kundenummer og/eller fakturanummer ", <br />, <br />, " Skriv inn kundenummeret 8- eller 9-sifret og fakturanummer 6-sifret. Skriv kun sifre.  ", <br />, <br />, " Kontakt oss gjerne på kunde@godtbrod.no ved ytterligere spørsmål eller hvis du ikke finner fakturakopi."])
    } else {
      setimageDataLoading(true)
      setimageData(resultXML.slice(47, -10))
      downloadPDF(resultXML.slice(47, -10))
      setimageDataText("")
      
    }
   
    setDataLoading(false)
  }

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Godt Brød fakturaportal</title>

      </Helmet>
      <img id="logoImg" style={{ position: "relative", width: "10vw", left: "0px" }} src='https://fornebu-s.no/wp-content/uploads/sites/7/2021/03/Godt-brod-logo.png'></img>
      <span id="toolbar">
        <span style={{ padding: "3%", display: "flex" }}>
          <p>Kundenummer: </p>
          <input style={{ marginLeft: "3%" }} placeholder="Skriv her" type="number" onChange={e => setkundeID(e.target.value)}></input>
        </span>
        <span style={{ padding: "3%", display: "flex" }}>
          <p>Fakturanummer:</p>
          <input style={{ marginLeft: "3%" }} type="number" onChange={e => setfakturaID(e.target.value)} placeholder="Skriv her"></input>
        </span>
        <button style={{ height: "3%" }} className="pageBTN button" id="getInvoiceBTN" variant="outlined" onClick={getFaktura}>{isBrowser ?"Hent faktura"  :"Lagre faktura" }  {DataLoading ? <CircularProgress fontSize={"small"} /> : null}</button >
        {imageDataLoading ? null : null}
      
        {/*<button style={{ height: "5vw", margin: "2%" }} variant="outlined" className="pageBTN button" onClick={() => (setimageData(""), setimageDataLoading(false))}>Lukk faktura</button>*/}
      </span>


      {imageDataLoading && isBrowser
        ? <iframe title="test" style={{ height: '99vh', width: '99.7vw' }} src={`data:application/pdf;base64,${imageData}`} />
        : <span><h1>Skriv inn kundenummer og fakturanummer</h1><p>{imageDataText}</p></span>
      }
    </div>
  );
}

